.networking-messages {
  height: 50vh;
  overflow-y: auto;
}

.networking-message {
  display: flex;
  align-items: center;
  margin: 2em 0 0 0;
  gap: 10px;
  &--currentUser {
    justify-content: flex-start;
    .networking-message__user {
      align-items: flex-start;
      order: 1;
      p {
        background-color: $black;
        color: $white;
      }
    }
  }
  &--otherUser {
    justify-content: flex-end;
    .networking-message__user {
      align-items: flex-end;
      p {
        background-color: $white;
        box-shadow: 0px 2px 3px #0000001a;
        background-color: $white;
      }
    }
  }
  &__user {
    height: 100%;
    min-height: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding: 0 1em;
      margin: 0;
      display: flex;
      align-items: center;
      flex: 3;
      border-radius: 5px;
    }
    span {
      font-size: 11px;
      font-weight: bold;
      color: $text-grey;
    }
  }
}

.networking-aside-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;
  padding: 20px 30px;
  transition: background-color 0.3s ease-in-out;
  // &:hover {
  //   background-color: $xiaomi;
  //   cursor: pointer;
  //   .person__wrapper {
  //     color: $white;
  //     small {
  //       color: $white;
  //     }
  //   }
  // }
  &--active {
    background-color: $xiaomi;
    cursor: pointer;
    .person__wrapper {
      color: $white;
      small {
        color: $white;
      }
    }
  }
  &__wrapper {
    border: 1px solid $text-grey2;
    &:hover {
      background-color: $xiaomi;
      cursor: pointer;
      .person__wrapper {
        color: $white;
        small {
          color: $white;
        }
      }
      .networking-aside-tile__status {
        background-color: $xiaomi;
      }
    }
    &--active {
      background-color: $xiaomi;
      cursor: pointer;
      .person__wrapper {
        color: $white;
        small {
          color: $white;
        }
      }
      .networking-aside-tile__status {
        background-color: $xiaomi;
      }
    }
  }
  &__status {
    background-color: #f7f8fc;
    margin-left: 5px;
  }
}

.networking-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $text-grey2;
  border-top: none;
  padding: 20px 30px;
  height: 102px;
  div {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: 0;
      cursor: unset;
    }
  }
  button {
    border: 0;
    cursor: unset;
    background: $xiaomi;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }
}
