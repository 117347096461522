.login-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  position: relative;

  &__links {
    text-align: center;
    padding: 0 0 1em 0;

    > * {
      display: block;
      margin: 1em 0;
    }
  }

  &__link {
    font-weight: 600;
  }

  &-form-row {
    display: flex;
    gap: 15px;

    &__item {
      display: flex;
      flex-direction: column;
      width: calc(50% - 7.5px);
    }
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 600;
  }

  p {
    margin: 12px 0 24px;
    color: $text-grey;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 4em 0 1em 0;
  }

  label {
    font-weight: 600;
  }

  &__input {
    height: 60px;
    min-height: 60px;
    background-color: $white;
    margin: 0.5em 0 2em 0;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    box-shadow: #0000001a 0px 3px 8px;
    padding-left: 20px;
  }

  button {
    background-color: $xiaomi;
    border-radius: 5px;
    border: none;
    color: $white;
    height: 75px;
    margin: 2em 0 0 0;

    &:hover {
      background-color: darken($xiaomi, 5%);
    }
  }

  hr {
    width: 100%;
    border: 1px solid #dedede;
    margin: 1em 0 2em 0;
  }

  &-checkbox__container {
    display: block;
    position: relative;
    padding: 0 0 0 35px;
    margin: 0 0 0 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      height: 0;
      width: 0;
    }

    .login-page-checkbox__checkmark {
      display: block;
    }
  }

  &-checkbox__content {
    p {
      font-size: 11px;
      margin: 0;
      padding: 0;
    }
  }

  &-checkbox__link {
    padding: 0 0 0 35px;
    font-size: 11px;
    color: $xiaomi !important;
    text-decoration: underline;
  }

  &-checkbox__wrapper {
    margin-top: 12px;
  }

  &-checkbox__checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    border-radius: 50%;
    border: 1px solid #e8e8e8;
    height: 25px;
    width: 25px;
    background-color: $white;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &-checkbox__container:hover input ~ &-checkbox__checkmark {
    background-color: lighten($xiaomi, 40%);
    border: none;
  }

  &-checkbox__container input:checked ~ &-checkbox__checkmark {
    background-color: $xiaomi;
    border: none;
  }

  &-checkbox__container input:checked ~ &-checkbox__checkmark:after {
    display: block;
  }

  &-checkbox__container &-checkbox__checkmark:after {
    left: 8.5px;
    top: 3.5px;
    width: 7.5px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
